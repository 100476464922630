@import "vars";

$button-border: rgba(255, 255, 255, .6);

button:not(.border-2, .border) {
  border: 0;
  outline: none;
}

.Button {
  position: relative;
  border: 1px solid $button-border;
  border-radius: 3px;
  color: $primary-text-on-dark-background;
  background-color: $primary-color;
  outline: none;
  font-size: .9rem;
  font-weight: 600;
  letter-spacing: .05rem;
  cursor: pointer;
  transition: all .15s;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0 8px;

  &:disabled {
    /*border: 1px solid $button-border !important;
    color: $primary-text-on-light-background !important;
    background-color: #fff !important;*/
    opacity: .7;
    cursor: not-allowed;
  }

  &--Next {
    cursor: pointer;
    display: none;
    z-index: 11;
    border: 0;
    border-radius: 0;
    background-image: url("../images/baseline-navigate_next-24px.svg");
    background-size: 24px;
    margin-top: 0;
    color: $primary-text-on-dark-background;
    background-position: center center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 6px;
    bottom: 0;

    .Button__Loader {
      right: 2px;
      background-color: $primary-color;

      &:after {
        background-color: $primary-color;
      }
    }
  }

  &--Loading, &[data-loading="true"] {
    pointer-events: none;
    color: $primary-text-on-light-background !important;
    background: #fff;
    cursor: progress;

    &.Button--Next {
      background: transparent;
    }

    .Button__Loader {
      display: flex;
    }

    .Button__Label {
      transform: translateX(-12px);
      //display: none;
    }
  }

  &--Light {
    border: 1px solid $button-border;
    color: $primary-text-on-light-background;
    background-color: #fff;
  }

  &__Label {
    padding: 0 12px;
    transition: transform .15s;
  }

  &__Loader {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    display: none;
    width: 16px;
    justify-content: center;
    align-items: center;

    &:after {
      display: block;
      content: "";
      width: 16px;
      height: 16px;

      box-sizing: border-box;
      border-radius: 50%;
      border: 2px solid currentColor;
      border-top-color: #fff;
      animation: spinner2 800ms linear infinite;
    }
  }

  &--Next .Button__Loader:after {
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    border-top-color: $primary-light-color;
  }

  &--Wide {
    min-width: 160px;
  }

  &:not(&--Light):hover {
    background-color: $button-border;
    color: $primary-dark-color
  }
}

@keyframes spinner2 {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
