@tailwind base;
@tailwind components;
@tailwind utilities;

@import "vars";
@import "button";

html, body, #root {
  height: 100%;
  background-color: #fbfbfb;
}

body {
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*input, select {
  font-family: 'Montserrat', sans-serif;
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}


.hidden, [hidden] {
  display: none;
}
