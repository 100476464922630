@import "vars";
@import "mixins";

.chartjs-tooltip {
  &__arrow {
    background: transparent;
    width: 16px;
    height: 70px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    margin-top: -35px;
    right: -16px;

    &:after {
      background: white;
      border: 2px solid white;
      content: "";
      position: absolute;
      top: 20px;
      left: -20px;
      width: 25px;
      height: 25px;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
      transform: rotate(45deg);
    }
  }
}

/*
SwiperJS styles
 */

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 0 !important;
  transition: opacity ease-in-out 300ms;
}

.swiper-button-next, .swiper-button-prev {
  top: unset !important;
  bottom: 0 !important;
  width: 44px !important;
  height: 100% !important;
}

.swiper-button-next {
  justify-content: end !important;
  right: 0 !important;
  background-image: linear-gradient(to right, rgba(249, 250, 251, 0), rgb(249, 250, 251), rgb(249, 250, 251));
}

.swiper-button-prev {
  justify-content: start !important;
  left: 0 !important;
  background-image: linear-gradient(to left, rgba(249, 250, 251, 0), rgb(249, 250, 251), rgb(249, 250, 251));
}

.swiper-button-next:after, .swiper-button-prev:after {
  color: #0CAAD0;
  font-size: 1.5rem !important;
}

/*
END SwiperJS styles
 */


.tutorial {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary-color;
  z-index: 502;
  padding: 8px 32px 16px 32px;
  max-height: 20vh;
  overflow: auto;
  height: 120px;

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .4);
    z-index: 500;
    display: none;

    &--active {
      display: block;
    }
  }

  &-exposed-element {
    z-index: 501;
  }

  &__actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
  }
}

/*.steps {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    position: absolute;
  }

  &__content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__field {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-field__label {
    margin-bottom: 48px;
  }

  .mdc-select {
    width: 100%;
    max-width: 340px;
  }

  &__menu {
    width: 340px;
  }
}*/


.icon {
  width: 20px;
  height: 20px;
  padding: 2px;
  box-sizing: border-box;
  display: inline-block;

  > svg {
    width: 16px;
    height: 16px;

    path, polygon {
      fill: currentColor;
    }
  }

  &--primary {
    color: $primary-dark-color
  }
}

/*::placeholder {
  color: currentColor;
  font-style: italic;
  padding: 0 2px;
}

input, ::placeholder {
  font-family: 'Titillium Web', sans-serif;
}*/


.spinner {
  display: block;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, .2);
  border-top-color: transparent;
  animation: spinner2 800ms linear infinite;

  &-parent-overlap {
    overflow: hidden;
    max-width: 100vw;
    max-height: 100vh;
  }

  &--inlined {
    display: inline-block;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
    padding-top: 16px;
    outline: none;
    box-sizing: border-box;

    &--full-height {
      background-color: $primary-color;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //height: 100vh;
      margin: 0;
      z-index: 49;

      .spinner {
        border: 2px solid rgba(255, 255, 255, .9);
        border-top-color: transparent;
      }
    }

    &--button {
      margin: 0 0 0 8px;
      padding: 0;

      .spinner {
        width: 16px;
        height: 16px;
        border: 2px solid currentColor;
        border-top-color: transparent;
      }
    }

    &--tall {
      padding-bottom: 16px;
    }
  }
}

/* PIE CHART START */

@property --p {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.pie {
  --p: 20;
  --b: 24px;
  --c: darkred;
  --w: 200px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
}

.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
  conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 2));
}

.animate {
  animation: p 1s .5s both;
}

@keyframes p {
  from {
    --p: 0
  }
}

/* PIE CHART END */
